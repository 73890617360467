<template>
  <van-pull-refresh
    v-model="isLoading"
    pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..."
    success-text="Refresh Berhasil"
    loading-text=" "
    @refresh="onRefresh"
  >
    <div class="content">
      <div class="top">
        <div class="name">
          <h4>Suku</h4>
          <h4 @click="visibleLogOut = true">Keluar</h4>
        </div>

        <div class="top_bg">
          <van-notice-bar
            color="#fff"
            background="#799af6"
            :left-icon="trumpet"
            @click="visible = true"
          >
            <span
              class="inform"
              v-for="(item, index) in this.form.msgList"
              :key="index"
              >{{ item }}</span
            >
          </van-notice-bar>
          <div class="jine_box">
            <div class="title">
              {{
                this.form.loanStatus == 5
                  ? " Total Pembayaran"
                  : "Pinjaman Hingga"
              }}
            </div>
            <div class="jine">
              Rp {{ formatNumberWithCommas(this.form.loanMoney) }}
            </div>
            <p>{{ form.loanStatus == 5 ? "Menunggu Pembayaran" : "" }}</p>
          </div>
          <!-- 状态1到9 -->
          <div class="jeikaun_xinxi" v-if="this.form.loanStatus != 5">
            <div class="li_lv" style="background: #e2f0ff">
              <div>Bunga Pinjaman</div>
              <span>{{ this.form.interestRate }}</span>
            </div>
            <div class="tian_shu" style="background: #e2f0ff">
              <div>Durasi Pinjaman</div>
              <span>{{ this.form.loanTerm }} Hari</span>
            </div>
          </div>
          <!-- 状态5 -->
          <div class="jeikaun_xinxi" v-else>
            <div class="tian_shu" style="background: #ffeee4">
              <div>Jumlah Hari Keterlambatan</div>
              <span>{{ this.form.loanTerm }} Hari</span>
            </div>
            <div class="li_lv" style="background: #ffeee4">
              <div>
                <p>Tanggal</p>
                <p>Pembayaran</p>
              </div>
              <span>{{ this.form.interestRate }}</span>
            </div>
          </div>
          <!-- 状态1-9按钮 -->
          <img
            v-if="this.form.loanStatus != 5"
            class="pinjam_uang"
            src="@/assets/img/pinjam_uang.png"
            @click="goState"
          />
          <!-- 状态5按钮 -->
          <img
            v-else
            class="pinjam_uang"
            src="@/assets/img/pinjam_uang5.png"
            @click="goState"
          />
        </div>
      </div>
      <div class="bottom">
        <img
          class="Semua_orabg_meminjam"
          src="@/assets/img/Semua_orabg_meminjam.png"
        />
        <div
          class="productCard"
          v-for="(item, index) in this.form.marketList"
          :key="index"
          @click="goOther(item)"
        >
          <div class="product">
            <img :src="item.product_logo" alt="" />
            <div class="productName">
              <div>{{ item.product_name }}</div>
              <span
                :style="{
                  color: item.product_status == 5 ? '#FF9A37' : '#666666',
                }"
                >{{ item.product_title }}</span
              >
            </div>
            <div
              :class="
                item.product_click == 1 ? 'text_btn' : 'text_btn_disenable'
              "
              :style="{
                background:
                  item.product_click != 1
                    ? '#eceef0'
                    : item.product_status == 5
                    ? '#f58446'
                    : '#4787f5',
              }"
            >
              {{ item.product_button }}
            </div>
          </div>
          <div
            class="juta"
            v-for="(productItem, productIndex) in item.product_list"
            :key="productIndex"
          >
            <div v-for="(value, key) in productItem" :key="key">
              <span>{{ key }}:</span>
              <span>{{
                typeof value === "number"
                  ? "Rp" + formatNumberWithCommas(value)
                  : value
              }}</span>
            </div>
          </div>

          <div class="describe" v-if="item.msgList.length > 0">
            <div v-for="(msgItem, msgIndex) in item.msgList" :key="msgIndex">
              {{ msgItem }}
            </div>
          </div>
        </div>
      </div>

      <div class="advertising" v-show="advertisingShow" @click="goAdvertising">
        <img src="" @click="advertisingShow = false" />
      </div>
      <van-dialog v-model="visible" :showConfirmButton="false">
        <div class="dialog">
          <p class="title">{{ this.form.msgList[0] }}</p>
          <p class="tip">
            {{ this.form.msgList[1] }}
          </p>
          <button type="button" class="btn" @click="visible = false">
            Setuju
          </button>
        </div>
      </van-dialog>
    </div>
    <van-dialog
      v-model="visibleLogOut"
      show-cancel-button
      cancel-button-text="Batal"
      confirm-button-text="Setuju"
      @cancel="visibleLogOut = false"
      @confirm="logout"
    >
      <h3 class="logout_text">yakin untuk keluar?</h3>
    </van-dialog>
  </van-pull-refresh>
</template>

<script>
import request from '@/utils/request'
import Vue from 'vue'
import { NoticeBar, Dialog, PullRefresh } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import trumpet from '@/assets/img/trumpet.png'
import store from '@/store'

Vue.use(NoticeBar)
Vue.use(PullRefresh)

export default {
  name: 'Home',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      trumpet: trumpet,
      isLoading: false,
      isRequest: false,
      advertisingShow: true,
      visibleLogOut: false,
      form: {
        marketList: [],
        msgList: [],
        product_list: []
      }
    }
  },
  created () {
    this.getState()
    const t = this
    // 添加 visibilitychange 事件监听器
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        // 页面变为可见时执行的逻辑
        t.getState()
      }
    })
  },
  methods: {
    // 下拉刷新
    onRefresh () {
      this.getState()
    },
    // 获取状态
    getState () {
      request.post('marketHomepage').then(res => {
        this.form = res
        this.isLoading = false
      })
    },
    // 去首页
    goState () {
      this.$router.push({
        path: '/home'
      })
    },
    // 跳转到其他项目
    goOther (item) {
      if (this.isRequest) {
        return
      }
      this.isRequest = true
      if (item.product_click === 2) {
        return
      }
      const phone = item.jump_url.match(/phone=([^&]*)/)[1]
      // 给定的完整 URL 字符串
      const urlString = item.jump_url
      // 使用字符串截取方法获取不带参数的跳转地址
      let redirectUrl = urlString.split('?')[0]
      // 判断最后一位是否是 '/'
      if (redirectUrl.endsWith('/')) {
        // 去掉最后一位
        redirectUrl = redirectUrl.slice(0, -1)
      }
      const abcdefgh = localStorage.getItem('abcdefgh')
      let obj = {}
      if (abcdefgh) {
        obj = JSON.parse(abcdefgh)
      }
      // 获取登录信息   往哪跳使用哪个产品的请求地址   as项目
      request.post('https://lunsapi.lunasol.cc/queryUserInfo', { phone }).then(res => {
        window.location = `${redirectUrl}/transfer?data=${JSON.stringify(res)}&a=${obj.a}&b=${obj.b}&c=${obj.c}&d=${obj.d}&e=${obj.e}&f=${obj.f}&g=${obj.g}&h=${obj.h}&SSO=1&laitteen=${res.dedrviceNo}`
        // window.location = `http://localhost:8081/transfer?data=${JSON.stringify(res)}&a=${obj.a}&b=${obj.b}&c=${obj.c}&d=${obj.d}&e=${obj.e}&f=${obj.f}&g=${obj.g}&h=${obj.h}&SSO=1&laitteen=${res.dedrviceNo}`
      }).finally(() => {
        setTimeout(() => {
          this.isRequest = false
        }, 300)
      })
    },
    // BRD
    goAdvertising () {
      window.location = 'https://apps.apple.com/id/app/brd-meminta-bantuan/id1662191053?l=id'
    },
    formatNumberWithCommas (number) {
      if (!number) {
        return ''
      }
      // 先将数字转换为字符串
      const numStr = String(number)

      // 使用正则表达式匹配数字的整数部分和小数部分
      const parts = numStr.split('.')
      let integerPart = parts[0]
      const decimalPart = parts.length > 1 ? '.' + parts[1] : ''

      // 对整数部分添加千分位分隔符
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      // 拼接整数部分和小数部分
      return integerPart + decimalPart
    },
    logout () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      localStorage.removeItem('userInfo')
      const fullPath = this.$route.fullPath
      this.$router.push({
        path: '/login',
        query: {
          redirectUrl: fullPath
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.content {
  background: #f0f2f5;
  min-height: 100vh;
  .advertising {
    height: 90px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-size: 100% 100%;
    img {
      position: absolute;
      width: 28px;
    }
  }
}
.top {
  background: url("~@/assets/img/top_bg.png");
  background-size: 100% 100%;
  height: 950px;
  .name {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 34px;
    color: #ffffff;
    padding: 28px 40px;
  }
  .top_bg {
    /deep/ .van-icon__image {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    /deep/ .van-notice-bar {
      border-radius: 60px;
      margin: 0 20px;
      height: 88px;
    }
    .jine_box {
      margin: 0 auto;
      margin-top: 50px;
      width: 525px;
      background: #ffffff;
      border-radius: 10px;
      padding-top: 17px;
      padding-bottom: 30px;

      .title {
        text-align: center;
        font-weight: 600;
        font-size: 28px;
        color: #000000;
        margin-bottom: 15px;
      }
      .jine {
        margin: 0 auto;
        text-align: center;
        font-weight: bold;
        font-size: 69px;
        color: #000000;
      }
      p {
        height: 20px;
        margin-top: 10px;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        color: #f58447;
      }
    }

    .jeikaun_xinxi {
      display: flex;
      justify-content: space-evenly;
      gap: 30px;
      margin-top: 100px;
      font-size: 26px;
      .li_lv,
      .tian_shu {
        text-align: center;
        width: 319px;
        height: 200px;
        background: #e3f0ff;
        border-radius: 28px;
        div {
          margin-top: 50px;
          font-weight: 600;
          font-size: 26px;
          color: #000;
          margin-bottom: 18px;
        }
        span {
          font-weight: bold;
          font-size: 40px;
          color: #000;
        }
      }
    }
    .pinjam_uang {
      width: 452px;
      height: 110px;
      margin: 0 auto;
      margin-top: 60px;
    }
  }
}
.bottom {
  padding: 10px 32px;
  .Semua_orabg_meminjam {
    height: 145px;
    margin-bottom: 37px;
  }
  .productCard {
    margin-bottom: 37px;
    width: 634px;
    background: #ffffff;
    border-radius: 28px;
    border: 1px solid #e3e3e3;
    padding: 26px;
    padding-top: 36px;
    padding-bottom: 10px;
    .product {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 95px;
        height: 95px;
        margin-right: 10px;
      }
      .productName {
        font-size: 22px;
        div {
          font-weight: 600;
          font-size: 30px;
          color: #333333;
          margin-bottom: 8px;
        }
        span {
          font-weight: 400;
          font-size: 22px;
          color: #666666;
        }
      }
      .text_btn {
        margin-left: auto;
        font-weight: 600;
        font-size: 24px;
        color: #fff;
        line-height: 65px;
        text-align: center;
        width: 238px;
        height: 65px;
        background: #4787f5;
        border-radius: 33px;
      }
      .text_btn_disenable {
        .text_btn;
        color: #fff;
      }
    }
  }
  .juta {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: 69px;
      background: #f8f8f8;
      margin-top: 21px;
      padding: 0 20px;
      span:nth-child(2) {
        font-weight: 600;
        font-size: 26px;
        color: #333333;
      }
    }

    div:nth-child(odd) {
      font-weight: 400;
      font-size: 26px;
      color: #666666;
    }
    div:nth-child(even) {
      font-weight: 400;
      font-size: 26px;
      color: #666666;
      background: none;
      margin-top: 0;
      height: 80px;
    }
  }

  .describe {
    padding: 20px;
    padding-top: 10px;
    background: #f8f8f8;
    font-weight: 400;
    font-size: 26px;
    color: #666666;
    line-height: 37px;
    margin-bottom: 30px;
    border-radius: 12px;
  }
}
.dialog {
  padding: 40px 30px;
  .title {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .tip {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 70px;
  }
  .btn {
    width: 100%;
    height: 100px;
    border-radius: 15px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    background: #3a3a3a;
  }
}
.logout_text {
  color: #000;
  font-size: 46px;
  margin: 60px 0px;
  margin-left: 30px;
}
</style>
